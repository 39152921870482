import React, { useState, useEffect } from 'react';
import { Navbar, Nav, Container, Button } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import './Navbar.css';
import logo from '../../assets/logo1.png'; // Correctly import the logo image

const NavigationBar = () => {
  const [isNavbarVisible, setIsNavbarVisible] = useState(true);

  useEffect(() => {
    const handleScroll = () => {
      setIsNavbarVisible(window.scrollY < window.innerHeight);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <Navbar
      bg="white"
      expand="lg"
      className={isNavbarVisible ? 'navbar-visible' : 'navbar-hidden'}
    >
      <Container>
        <Navbar.Brand href="/">
          <img src={logo} className="logo-image" alt="EnviGrow" />
          <span className="brand-name">EnviGrow Sustainable Solutions</span>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="navbar-link">
            <Nav.Link href="/">HOME</Nav.Link>
            <Nav.Link href="/about-us">WHO WE ARE</Nav.Link>
            <Nav.Link href="/HOW-WE-DO">HOW WE DO</Nav.Link>
            <Nav.Link href="/MEDIA">MEDIA</Nav.Link>
          </Nav>
          <Button variant="success" href="/career" className="ml-3" style={{ backgroundColor: '#0c90a4' }}>
            Careers
          </Button>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default NavigationBar;
